import { LoadingButton } from "@mui/lab";
import {
  Stack,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  ListItemText,
  SelectChangeEvent,
  Checkbox,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { oneBranchHasPermission } from "src/utils";

interface IProps {
  name: string;
  onDownload: any;
  isLoading: boolean;
  options?: any[];
  value?: string;
  setValue?: any;
  selectLabel?: string;
  multiSelect?: boolean;
  multiValue?: string[];
  setMultiValue?: any;
}

const ReportBox = ({
  name,
  onDownload,
  isLoading,
  options,
  value,
  setValue,
  selectLabel,
  multiSelect = false,
  multiValue = [],
  setMultiValue,
}: IProps) => {
  const { t } = useTranslation("reports");

  // permissions
  const downloadReportsPermission = oneBranchHasPermission("download_third_eye_report");

  const handleChangeMulti = (event: SelectChangeEvent<typeof multiValue>) => {
    const {
      target: { value },
    } = event;
    setMultiValue(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <Stack
      bgcolor={"#F2F4F7"}
      borderRadius={"8px"}
      overflow={"hidden"}
      position={"relative"}
    >
      <Box
        position={"absolute"}
        top={0}
        right={0}
        bgcolor={"#fff"}
        height={"40px"}
        width={"40px"}
        boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
      />
      <Box minHeight={"100px"} padding={"70px 5px 50px 5px"}>
        <Typography fontSize={"18px"} fontWeight={600} align="center">
          {name}
        </Typography>
      </Box>
      <Stack direction={"row"} gap={1} p={"9px 19px"} bgcolor={"#EAECF0"}>
        {options && options?.length > 0 && (
          <>
            {multiSelect ? (
              <FormControl fullWidth size="small">
                <InputLabel id="demo-multiple-checkbox-label">
                  {selectLabel}
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={multiValue}
                  onChange={handleChangeMulti}
                  input={<OutlinedInput label={selectLabel} />}
                  renderValue={(selected) => selected.join(", ")}
                  sx={{ bgcolor: "#FFF" }}
                >
                  {options?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      <Checkbox
                        checked={multiValue?.indexOf(option.id) > -1}
                      />
                      <ListItemText primary={option.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <FormControl fullWidth size="small">
                <InputLabel id="demo-multiple-checkbox-label">
                  {selectLabel}
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  input={<OutlinedInput label={selectLabel} />}
                  sx={{ bgcolor: "#FFF" }}
                >
                  {options?.map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      <ListItemText primary={option.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </>
        )}
        
        <LoadingButton
          onClick={onDownload}
          loading={isLoading}
          sx={{ bgcolor: "#F2F4F7" }}
          fullWidth
          variant="outlined"
          color="tertiary"
          disabled={!downloadReportsPermission}
        >
          {t("download")}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default ReportBox;
