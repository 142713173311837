import { IconButton, Stack } from "@mui/material";
import EditIcon from "src/assets/svg-icons/aggregators/edit.svg";
import DeleteIcon from "src/assets/svg-icons/aggregators/delete.svg";
import AddEditBranch from "../../add-new-account";
import { useEffect, useState } from "react";
import { useDeleteOCAccountMutation } from "src/api/operation-cost/accounts";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { ConfirmPopup } from "src/shared/components/confirm-popup";
import { useTranslation } from "react-i18next";
import { oneBranchHasPermission } from "src/utils";
import { OCPermissions } from "src/constants/permissions";

const TableActions = ({ row, refetch }: { row: any; refetch: Function }) => {
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [open, setOpen] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const { t } = useTranslation("general");

  //permissions
  const editPermission = oneBranchHasPermission(OCPermissions.editOCAccount);
  const deletePermission = oneBranchHasPermission(
    OCPermissions.deleteOCAccount
  );

  // APIs
  const { mutate, isPending, status, error } = useDeleteOCAccountMutation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenEditPopup = () => setOpenEditPopup(true);
  const handleCloseEditPopup = () => setOpenEditPopup(false);

  const handleDelete = () => {
    mutate(row.id);
  };

  useEffect(() => {
    if (status === "success") {
      refetch();
      handleClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: t("deletedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || t("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Stack
      direction={"row"}
      spacing={"12px"}
      width={"100%"}
      justifyContent={"center"}
    >
      {editPermission && (
        <IconButton onClick={handleOpenEditPopup}>
          <img src={EditIcon} alt="" />
        </IconButton>
      )}

      {deletePermission && (
        <IconButton onClick={handleOpen}>
          <img src={DeleteIcon} alt="" />
        </IconButton>
      )}

      <AddEditBranch
        open={openEditPopup}
        handleClose={handleCloseEditPopup}
        row={row}
        refetch={refetch}
      />

      <ConfirmPopup
        open={open}
        item={row.name}
        handleClose={handleClose}
        handleConfirm={handleDelete}
        isPending={isPending}
      />
    </Stack>
  );
};
export default TableActions;
