import { IconButton, Stack } from "@mui/material";
import EditIcon from "src/assets/svg-icons/aggregators/edit.svg";
import DeleteIcon from "src/assets/svg-icons/aggregators/delete.svg";
import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import AddEditPayment from "../../add-edit-payment";
import { ConfirmPopup } from "src/shared/components/confirm-popup";
import { useDeletePaymentMutation } from "src/api/settings/payments";
import { useTranslation } from "react-i18next";
import { oneBranchHasPermission } from "src/utils";
import { SettingsPermissions } from "src/constants/permissions";

const TableActions = ({ row, refetch }: { row: any; refetch: Function }) => {
  const { t } = useTranslation("general");
  const [open, setOpen] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const { mutate, isPending, status, error } = useDeletePaymentMutation();

  // permissions
  const editPermission = oneBranchHasPermission(
    SettingsPermissions.editPayment
  );
  const deletePermission = oneBranchHasPermission(
    SettingsPermissions.deletePayment
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenEditModal = () => {
    setOpenEditModal(true);
  };
  const handleCloseEditModal = () => setOpenEditModal(false);

  const handleDelete = () => {
    mutate(row.id);
  };

  useEffect(() => {
    if (status === "success") {
      refetch();
      handleClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: t("deletedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || t("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Stack
      direction={"row"}
      spacing={"12px"}
      width={"100%"}
      justifyContent={"center"}
    >
      {editPermission && (
        <IconButton onClick={handleOpenEditModal}>
          <img src={EditIcon} alt="" />
        </IconButton>
      )}

      {deletePermission && row.type !== "other_revenue" && (
        <IconButton onClick={handleOpen}>
          <img src={DeleteIcon} alt="" />
        </IconButton>
      )}

      <ConfirmPopup
        open={open}
        item={row.name}
        handleClose={handleClose}
        handleConfirm={handleDelete}
        isPending={isPending}
      />

      <AddEditPayment
        paymentData={row}
        open={openEditModal}
        handleClose={handleCloseEditModal}
        refetch={refetch}
      />
    </Stack>
  );
};
export default TableActions;
