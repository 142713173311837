import { useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "../apiClient";

interface IParams {
  page?: number;
  branch_id: number | undefined;
  name?: string;
  sku?: string;
}

export const useGetLevelsList = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: [
      "get-inventory-levels-list",
      params.page,
      params.branch_id,
      params.name,
      params.sku,
      params.page,
    ],
    queryFn: async () => {
      return apiClient.get(`/api/levels`, { params });
    },
    gcTime: 0,
    enabled: !!params.branch_id,
  });

export const useDownloadLevelsList = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: [
      "download-levels-list",
      params.branch_id,
      params.name,
      params.page,
    ],
    queryFn: async () => {
      return apiClient.get(`/api/levels/download`, { params });
    },
    enabled: false,
    gcTime: 0,
  });
