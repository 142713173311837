import { IconButton, Stack, Tooltip } from "@mui/material";
import EditIcon from "src/assets/svg-icons/aggregators/edit.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteIcon from "src/assets/svg-icons/aggregators/delete.svg";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useNavigate } from "react-router-dom";
import {
  useDeleteStockCountMutation,
  useDownloadStockCountDetails,
} from "src/api/inventory/counts";
import { ConfirmPopup } from "src/shared/components/confirm-popup";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import { downloadFile, specificBranchHasPermission } from "src/utils";
import { InventoryPermissions } from "src/constants/permissions";

const TableActions = ({ row, refetch }: { row: any; refetch: Function }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const { t: generalT } = useTranslation("general");

  // APIS
  const { mutate, isPending, status, error } = useDeleteStockCountMutation();
  const {
    data: downloadedData,
    refetch: refetchDownload,
    isFetching: isFetchingDownload,
    status: downloadStatus,
  } = useDownloadStockCountDetails({
    stockCountId: row.id,
  });

  // permissions
  const addPermission = specificBranchHasPermission(
    InventoryPermissions.addStocktaking,
    row.branch?.id
  );

  const deletePermission = specificBranchHasPermission(
    InventoryPermissions.deleteStocktaking,
    row.branch?.id
  );

  const confirmPermission = specificBranchHasPermission(
    InventoryPermissions.confirmStocktaking,
    row.branch?.id
  );

  const deleteConfirmedPermission = specificBranchHasPermission(
    InventoryPermissions.deleteConfirmedStocktaking,
    row.branch?.id
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDelete = () => {
    mutate(row.id);
  };

  const handleDownload = () => {
    refetchDownload();
  };

  const handleNavigate = () => {
    navigate(`/counts/${row.id}/edit`);
  };

  useEffect(() => {
    if (status === "success") {
      refetch();
      handleClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("deletedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  useEffect(() => {
    if (
      downloadStatus === "success" &&
      !!downloadedData &&
      !isFetchingDownload
    ) {
      const utf8CSVData = `\uFEFF${downloadedData}`;
      downloadFile(utf8CSVData, "text/csv", `stocktaking-${row.reference}`);
    }
  }, [isFetchingDownload]);

  return (
    <Stack
      direction={"row"}
      spacing={"12px"}
      width={"100%"}
      justifyContent={"center"}
    >
      {((addPermission && row.status === "draft") ||
        (confirmPermission && row.status !== "draft")) && (
        <Tooltip
          title={generalT(row.status === "confirmed" ? "details" : "edit")}
        >
          <IconButton onClick={handleNavigate}>
            {row.status === "confirmed" ? (
              <VisibilityOutlinedIcon fontSize="small" />
            ) : (
              <img src={EditIcon} alt="" />
            )}
          </IconButton>
        </Tooltip>
      )}

      {((deletePermission && row.status !== "confirmed") ||
        (deleteConfirmedPermission && row.status === "confirmed")) && (
        <Tooltip title={generalT("delete")}>
          <IconButton onClick={handleOpen}>
            <img src={DeleteIcon} alt="" />
          </IconButton>
        </Tooltip>
      )}

      <Tooltip title={generalT("download")}>
        <IconButton onClick={handleDownload} disabled={isFetchingDownload}>
          <img src={DownloadIcon} alt="" />
        </IconButton>
      </Tooltip>

      {/* confirm delete popup */}
      <ConfirmPopup
        open={open}
        item={row.reference}
        handleClose={handleClose}
        handleConfirm={handleDelete}
        isPending={isPending}
      />
    </Stack>
  );
};
export default TableActions;
