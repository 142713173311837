import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import AllTable from "./all";
import DeletedTable from "./deleted";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import AddEditSuppliesPopup from "../add-edit-supplier";
import { oneBranchHasPermission } from "src/utils";
import { PurchasingPermissions } from "src/constants/permissions";

interface TabProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface IProps {
  handleOpen: () => void;
}

function CustomTabPanel(props: TabProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }} height={"40px"}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SuppliesTabs = () => {
  const [value, setValue] = useState(0);
  const { t } = useTranslation("purchasing");
  const [open, setOpen] = useState(false);
  const [toRefetch, setToRefetch] = useState(false);

  const labels = [t("all"), t("deleted")];

  //  permissions
  const addSupplierPermission = oneBranchHasPermission(
    PurchasingPermissions.addSupplier
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleRefetch = () => {
    setToRefetch((prev) => !prev);
  };

  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Tabs value={value} onChange={handleChange}>
          {labels.map((label, index) => {
            return (
              <Tab
                disableRipple
                key={index}
                label={label}
                {...a11yProps(index)}
              />
            );
          })}
        </Tabs>
        <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
          <Button
            sx={{
              border: "1px solid #98A2B3",
              height: "40px",
              fontWeight: 500,
              borderRadius: "4px",
              padding: "7px 10px",
              background: "#FCFCFD",
              fontSize: "16px",
            }}
            color="tertiary"
            variant="outlined"
            endIcon={<img src={DownloadIcon} alt="" />}
          >
            {t("download")}
          </Button>
          {addSupplierPermission && (
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleOpen}
              size="small"
            >
              {t("createSupplier")}
            </Button>
          )}
        </Stack>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <AllTable toRefetch={toRefetch} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <DeletedTable />
      </CustomTabPanel>
      <AddEditSuppliesPopup
        open={open}
        handleClose={handleClose}
        refetch={handleRefetch}
      />
    </Box>
  );
};

export default SuppliesTabs;
