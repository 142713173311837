import { Box, Typography } from "@mui/material";
import React from "react";

const NoAccessPage: React.FC = () => {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      minHeight={"400px"}
    >
      <Typography component={"h2"}>You have no access!</Typography>
    </Box>
  );
};

export default NoAccessPage;
