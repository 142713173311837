import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableActions from "./table-actions";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TablePagination,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useDownloadTransferReport,
  useGetTransfersList,
} from "src/api/inventory/transfer";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import FilterIcon from "src/assets/svg-icons/aggregators/filter.svg";
import AddIcon from "@mui/icons-material/Add";
import AddEditTransfer from "../add-edit-transfer";
import SecondaryChip from "src/shared/components/secondary-chip";
import { downloadFile, oneBranchHasPermission } from "src/utils";
import { InventoryPermissions } from "src/constants/permissions";

const TransferTable = () => {
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [status, setStatus] = useState<string>("all");
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("inventory");
  const { t: generalT } = useTranslation("general");

  const tableHeadText = [
    generalT("reference"),
    t("from"),
    t("toDestination"),
    generalT("status"),
    t("totalItemsCost"),
    generalT("createdBy"),
    generalT("createdAt"),
    generalT("actions"),
  ];

  // APIs
  const { data, isLoading, refetch } = useGetTransfersList({
    page: pageNumber + 1,
    ...(status !== "all" ? { status: status } : {}),
  });
  const {
    data: downloadedData,
    refetch: refetchDownload,
    isFetching: isFetchingDownload,
    status: downloadStatus,
  } = useDownloadTransferReport({
    ...(status !== "all" ? { status: status } : {}),
  });

  const emptyDataArr = data?.data.length === 0;

  // permissions
  const addPermission = oneBranchHasPermission(
    InventoryPermissions.addTransfer
  );

  const handleChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDownload = () => {
    refetchDownload();
  };

  useEffect(() => {
    if (
      downloadStatus === "success" &&
      !!downloadedData &&
      !isFetchingDownload
    ) {
      const utf8CSVData = `\uFEFF${downloadedData}`;
      downloadFile(utf8CSVData, "text/csv", `transfers`);
    }
  }, [isFetchingDownload]);

  useEffect(() => {
    if (!!data) {
      setTotal(data.meta?.total as number);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [status, refetch]);

  console.log({ data, status });

  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={3}
      >
        <Box sx={{ minWidth: 150 }}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">{t("all")}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={status}
              label="all"
              onChange={handleChange}
            >
              <MenuItem value={"all"}>{t("all")}</MenuItem>
              <MenuItem value={"draft"}>{t("draft")}</MenuItem>
              <MenuItem value={"sent"}>{t("sent")}</MenuItem>
              <MenuItem value={"received"}>{t("confirmed")}</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Stack
          direction={"row"}
          flexWrap={"wrap"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          spacing={2}
        >
          <Button
            color="tertiary"
            variant="outlined"
            size="small"
            endIcon={<img src={FilterIcon} height={"16px"} alt="" />}
            sx={{ height: "40px" }}
          >
            {t("filter")}
          </Button>

          <Box sx={{ minWidth: 114 }}>
            <LoadingButton
              color="tertiary"
              variant="outlined"
              endIcon={<img src={DownloadIcon} alt="" />}
              onClick={handleDownload}
              loading={isFetchingDownload}
            >
              {t("download")}
            </LoadingButton>
          </Box>

          {addPermission && (
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleOpen}
            >
              {t("createTransfer")}
            </Button>
          )}
        </Stack>
      </Box>

      <TableContainer component={Paper} variant="outlined">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeadText.map((item) => (
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    lineHeight: "18px",
                    color: "#475467",
                    bgcolor: "#F9FAFB",
                  }}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableLoadingSkeleton rowsLength={8} cellsLength={8} />
          ) : (
            <TableBody>
              {data?.data.map((row: any) => {
                return (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">{row.reference}</TableCell>
                    <TableCell align="center">
                      {row.from_branch?.name}
                    </TableCell>
                    <TableCell align="center">{row.to_branch?.name}</TableCell>
                    <TableCell align="center">
                      <SecondaryChip
                        variant={
                          row.status === "draft"
                            ? "warning"
                            : row.status === "received"
                            ? "success"
                            : "info"
                        }
                        label={t(
                          row.status === "received" ? "confirmed" : row.status
                        )}
                      />
                    </TableCell>
                    <TableCell align="center">{row.total.toFixed(2)}</TableCell>
                    <TableCell align="center">{row.created_by?.name}</TableCell>
                    <TableCell align="center">{row.created_at}</TableCell>
                    <TableCell align="center">
                      <TableActions row={row} refetch={refetch} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
        {emptyDataArr && <NoData />}
      </TableContainer>
      <TablePagination
        sx={{ mt: 1 }}
        rowsPerPageOptions={[20]}
        component="div"
        count={total}
        rowsPerPage={20}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />
      {open && (
        <AddEditTransfer
          open={open}
          handleClose={handleClose}
          refetch={refetch}
        />
      )}
    </Box>
  );
};
export default TransferTable;
