import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableActions from "./table-actions";
import { NoData } from "src/shared/components/tables/no-data";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { useTranslation } from "react-i18next";
import { formatNumber } from "src/utils";
import { Chip, Typography } from "@mui/material";

const PurchasingTable = ({
  data,
  isFetching,
  refetch,
}: {
  data: any;
  isFetching: boolean;
  refetch: Function;
}) => {
  const emptyDataArr = data?.length === 0;
  const { t } = useTranslation("purchasing");
  const { t: generalT } = useTranslation("general");

  const HeadText = [
    t("reference"),
    t("branch"),
    t("supplier"),
    t("subtotal"),
    t("tax"),
    generalT("totalWithTax"),
    t("paymentMethod"),
    t("paymentDate"),
    t("invoiceDate"),
    t("dueDate"),
    t("type"),
    t("invoiceNumber"),
    t("source"),
    t("createdBy"),
    t("createdAt"),
    t("status"),
    t("actions"),
  ];

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {HeadText.map((item) => (
              <TableCell
                align={item === "actions" ? "right" : "center"}
                sx={{
                  bgcolor: "#F9FAFB",
                }}
              >
                <Typography
                  fontSize={"12px"}
                  color={"#475467"}
                  lineHeight={"18px"}
                  fontWeight={500}
                  whiteSpace={"nowrap"}
                >
                  {item}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {isFetching ? (
          <TableLoadingSkeleton rowsLength={15} cellsLength={17} />
        ) : (
          <TableBody>
            {data?.map((row: any) => (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                key={row.id}
              >
                <TableCell align="center" sx={{}}>
                  {row.reference}
                </TableCell>
                <TableCell align="center">{row.branch?.name}</TableCell>
                <TableCell align="center">{row.supplier?.name}</TableCell>
                <TableCell align="center" sx={{ fontWeight: 600 }}>
                  {formatNumber(row.amount)}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: 600 }}>
                  {formatNumber(row.tax)}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: 600 }}>
                  {formatNumber(row.total)}
                </TableCell>
                <TableCell align="center">
                  {row?.purchase_event?.from}
                  {row?.purchase_event?.from && row?.payments.length > 0
                    ? ", "
                    : ""}
                  {row.payments?.map((pay: any, index: number) => {
                    return `${pay.payment_method.name}${
                      index < row.payments.length - 1 ? ", " : ""
                    }`;
                  })}
                </TableCell>
                <TableCell align="center" dir="ltr" sx={{ minWidth: "112px" }}>
                  {row.payment_date || row?.purchase_event?.date}
                </TableCell>
                <TableCell align="center" sx={{ minWidth: "112px" }}>
                  {row.invoice_date}
                </TableCell>
                <TableCell align="center" sx={{ minWidth: "112px" }}>
                  {row.date}
                </TableCell>
                <TableCell align="center">{row.type}</TableCell>
                <TableCell align="center">{row.invoice_number}</TableCell>
                <TableCell align="center">{row.source}</TableCell>
                <TableCell align="center">{row.created_by?.name}</TableCell>
                <TableCell align="center" sx={{ minWidth: "120px" }}>
                  {row.created_at}
                </TableCell>
                <TableCell align="center">
                  <Chip
                    label={
                      row.status === "Paid"
                        ? generalT("paid")
                        : row.status === "Partly Paid"
                        ? generalT("partiallyPaid")
                        : row.status === "UnPaid"
                        ? generalT("unpaid")
                        : generalT("overpaid")
                    }
                    color={
                      row.status === "Paid"
                        ? "success"
                        : row.status === "Partly Paid"
                        ? "warning"
                        : "error"
                    }
                    variant="outlined"
                    sx={{ height: "22px" }}
                  />
                </TableCell>
                <TableCell align="center">
                  <TableActions row={row} refetch={refetch} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      {emptyDataArr && <NoData />}
    </TableContainer>
  );
};
export default PurchasingTable;
