import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "../apiClient";

interface IPaymentBody {
  id?: number;
  branch_id?: number;
  balance?: number;
  status?: boolean;
  is_discount?: 1 | 0;
  is_cash?: boolean
}

export const useAddPaymentMutation = () =>
  useMutation<void, APIErrorResponse, IPaymentBody>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/paymentMethods`, data);
    },
  });

export const useUpdatePaymentMutation = () =>
  useMutation<void, APIErrorResponse, IPaymentBody>({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      return apiClient.put(`/api/paymentMethods/${id}`, rest);
    },
  });

export const useDeletePaymentMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/paymentMethods/${id}`);
    },
  });

// Transactions tab
interface IParams {
  page?: number;
  start_date?: string;
  end_date?: string;
  branches?: number[];
  aggregators?: number[];
  reference_number?: string;
  payment_methods?: number[];
  // journeys?: number[];
}

export const useGetPaymentTransactionsList = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-payment-transactions-list", params.page],
    queryFn: async () => {
      return apiClient.get(`/api/payments`, { params });
    },
  });

export const useDownloadPaymentTransList = (params: any) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["download-payment-trans-list", params.refNum || ""],
    queryFn: async () => {
      return apiClient.get(`/api/payments/download`, { params });
    },
    enabled: false,
    gcTime: 0,
  });
