import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import All from "./all";
import Draft from "./draft";
import WasteFormula from "./waste-formula";
import Sent from "./sent";
import Confirmed from "./confirmed";
import { oneBranchHasPermission } from "src/utils";
import { InventoryPermissions } from "src/constants/permissions";
import NoAccessPage from "src/pages/no-access";

interface TabProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }} height={"40px"}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StockTakingTabs = () => {
  const [value, setValue] = useState(0);
  const { t } = useTranslation("inventory");

  const labels = [
    t("all"),
    t("draft"),
    t("sent"),
    t("confirmed"),
    t("wasteFormula"),
  ];

  //permissions
  const viewWasteFormula = oneBranchHasPermission(
    InventoryPermissions.viewWasteFormulas
  );
  const viewStocktaking = oneBranchHasPermission(
    InventoryPermissions.viewStocktaking
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Tabs value={value} onChange={handleChange}>
        {labels.map((label, index) => {
          return (
            <Tab
              disableRipple
              key={index}
              label={label}
              {...a11yProps(index)}
            />
          );
        })}
      </Tabs>

      <Box mt={{ xs: "-5px", md: "-5px" }}>
        <CustomTabPanel value={value} index={0}>
          {viewStocktaking ? <All /> : <NoAccessPage />}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {viewStocktaking ? <Draft /> : <NoAccessPage />}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          {viewStocktaking ? <Sent /> : <NoAccessPage />}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          {viewStocktaking ? <Confirmed /> : <NoAccessPage />}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          {viewWasteFormula ? <WasteFormula /> : <NoAccessPage />}
        </CustomTabPanel>
      </Box>
    </Box>
  );
};

export default StockTakingTabs;
