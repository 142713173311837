import { IconButton, Stack } from "@mui/material";
import EditIcon from "src/assets/svg-icons/aggregators/edit.svg";
import AddEditBranch from "../../add-edit-branch";
import { useState } from "react";
import { specificBranchHasPermission } from "src/utils";
import { SettingsPermissions } from "src/constants/permissions";
import { IBranchData } from "src/types/generic";

const TableActions = ({
  row,
  refetch,
}: {
  row: IBranchData;
  refetch: Function;
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // permissions
  const editPermission = specificBranchHasPermission(
    SettingsPermissions.editBranch,
    row.id
  );

  return (
    <Stack
      direction={"row"}
      spacing={"12px"}
      width={"100%"}
      justifyContent={"center"}
    >
      {editPermission && (
        <IconButton onClick={handleOpen}>
          <img src={EditIcon} alt="" />
        </IconButton>
      )}

      <AddEditBranch
        open={open}
        handleClose={handleClose}
        row={row}
        refetch={refetch}
      />
    </Stack>
  );
};
export default TableActions;
