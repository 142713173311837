import { Button, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AggregatorsTable from "./table";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { oneBranchHasPermission } from "src/utils";

const AggregatorsList = () => {
  const { t } = useTranslation("aggregators");
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/aggregators/add-agg");
  };

  // permissions
  const addAggPermission = oneBranchHasPermission("add_aggregator");

  return (
    <Stack spacing={3}>
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          fontWeight={600}
          lineHeight={"19.2px"}
          color={"#475467"}
          mb={{ xs: 1.5, md: 0 }}
          textTransform={"capitalize"}
        >
          {t("aggList")}
        </Typography>
        {addAggPermission && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleNavigate}
          >
            {t("addNewAgg")}
          </Button>
        )}
      </Stack>
      <AggregatorsTable />
    </Stack>
  );
};

export default AggregatorsList;
