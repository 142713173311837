import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Summary from "./Summary";
import AggregatorsList from "./aggregators-list";
import Transactions from "./transactions";
import { useMediaQuery } from "@mui/material";
import AggregatorFees from "./aggregator-fees";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { oneBranchHasPermission } from "src/utils";
import NoAccessPage from "src/pages/no-access";

interface TabProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }} height={"40px"}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AggregatorsTabs = () => {
  const { t } = useTranslation("aggregators");
  const navigate = useNavigate();
  const location = useLocation();
  const isSmallScreen = useMediaQuery("(max-width:700px)");
  const [dateRange, setDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: moment().startOf("month").format("yyyy-MM-DD"),
    endDate: moment().endOf("month").format("yyyy-MM-DD"),
  });

  // permissions
  const viewSummary = oneBranchHasPermission("view_aggregator_summary");
  const viewAggsList = oneBranchHasPermission("view_aggregator_list");
  const viewTrans = oneBranchHasPermission("view_aggregator_transactions");
  const viewCostFees = oneBranchHasPermission("view_aggregator_cost_and_fee");

  // Map paths to tab indices
  const tabPaths = [
    "/aggregators/summary",
    "/aggregators/list",
    "/aggregators/transactions",
    "/aggregators/fees",
  ];
  const currentPath = location.pathname;

  // Find the tab index based on the current URL path
  const currentTabIndex = tabPaths.indexOf(currentPath);
  const [value, setValue] = useState(
    currentTabIndex === -1 ? 0 : currentTabIndex
  );

  const labels = [
    t("summary"),
    t("aggList"),
    t("transactions"),
    t("aggsFeesCost"),
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    navigate(tabPaths[newValue]); // Change the URL based on the selected tab
  };

  // Update the tab value when the URL changes
  useEffect(() => {
    const newTabIndex = tabPaths.indexOf(currentPath);
    if (newTabIndex !== value) {
      setValue(newTabIndex === -1 ? 0 : newTabIndex);
    }
  }, [currentPath]);

  return (
    <Box>
      <Box
        bgcolor={"#F9FAFB"}
        p={0.5}
        border={"1px solid  #EAECF0"}
        borderRadius={1}
        width={{ xs: "100%", md: "fit-content" }}
        display={"flex"}
        justifyContent={isSmallScreen ? "center" : ""}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ style: { display: "none" } }}
          orientation={isSmallScreen ? "vertical" : "horizontal"}
          sx={{
            minHeight: { xs: "100%", md: "35px" },
            height: { xs: "100%", md: "35px" },
          }}
        >
          {labels.map((label, index) => (
            <Tab
              key={index}
              disableRipple
              sx={{
                borderRadius: "6px",
                color: "#667085",
                height: "33px",
                minHeight: "33px",
                "&.Mui-selected": {
                  bgcolor: "#FFFFFF",
                  color: "#344054",
                  boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
                  m: "1px",
                },
              }}
              label={label}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {viewSummary ? (
          <Summary dateRange={dateRange} setDateRange={setDateRange} />
        ) : (
          <NoAccessPage />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {viewAggsList ? <AggregatorsList /> : <NoAccessPage />}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        {viewTrans ? <Transactions /> : <NoAccessPage />}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        {viewCostFees ? <AggregatorFees /> : <NoAccessPage />}
      </CustomTabPanel>
    </Box>
  );
};

export default AggregatorsTabs;
