import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  FormHelperText,
  IconButton,
  LinearProgress,
  LinearProgressProps,
  Stack,
  Typography,
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import uploadIcon from "src/assets/svg-icons/upload.svg";
import XlsxIcon from "src/assets/svg-icons/xlsx_file.svg";
import { Controller } from "react-hook-form";
import { formatBytes } from "src/utils";
import { useTranslation } from "react-i18next";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

type FileUploaderProps = {
  onFileUpload: (file: Blob) => void;
  control?: any; // Pass the control prop from useForm
  name?: string; // Specify the name for the input
  label?: string;
  id?: string;
  error?: boolean;
  helperText?: string;
  isLoading?: boolean;
  reset?: Function;
  onFileDelete?: Function;
  value?: any;
  fileTypes?: string;
};

const FileUploader: React.FC<FileUploaderProps> = ({
  onFileUpload,
  label,
  id = "fileInput",
  control = {},
  name = "file",
  error = false,
  helperText = "",
  isLoading = false,
  reset = () => {},
  onFileDelete = () => {},
  value,
  fileTypes = ".xls, .xlsx, .csv",
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [progress, setProgress] = React.useState(0);
  const { t } = useTranslation("general");

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 5
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    console.log({ file });
    if (file) {
      setSelectedFile(file);
      handleFileUpload(file);
    }
  };

  const handleBoxClick = () => {
    // Trigger the hidden file input when the box is clicked
    document.getElementById(id)?.click();
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files && event.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file);
      handleFileUpload(file);
    }
  };

  const handleFileUpload = (file: File) => {
    onFileUpload(file);
  };

  const handleFileDelete = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setSelectedFile(null);
    onFileDelete();
    reset({
      [name]: "",
    });
  };

  useEffect(() => {
    if (!value) {
      handleFileDelete();
    }
  }, [value]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Stack alignItems={"flex-start"}>
          {label && <Typography mb={1.5}>{label}</Typography>}
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            border="1px solid #EAECF0"
            borderRadius="8px"
            padding="16px 24px"
            onClick={handleBoxClick}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            sx={{
              cursor: "pointer",
              pointerEvents: isLoading || selectedFile ? "none" : "unset",
            }}
            minWidth={"297px"}
          >
            <input
              {...field}
              key={field.value}
              value={undefined}
              type="file"
              accept={fileTypes}
              onChange={(e) => {
                const file = e.target.files && e.target.files[0];
                field.onChange(file);
                handleFileChange(e);
              }}
              style={{ display: "none" }}
              id={id}
              ref={fileInputRef}
            />

            {selectedFile ? (
              <Stack gap={"12px"} width={"100%"}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  gap={1}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <Stack direction={"row"} gap={"12px"}>
                    <img width={40} src={XlsxIcon} alt="" />
                    <div>
                      <Typography fontSize={"14px"}>
                        {selectedFile.name}
                      </Typography>
                      <Typography fontSize={"14px"} color={"textSecondary"}>
                        {formatBytes(selectedFile.size)}
                      </Typography>
                    </div>
                  </Stack>
                  {!isLoading && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        // setSelectedFile(null);
                        // onFileDelete();
                        // reset({
                        //   [name]: "",
                        // });
                        handleFileDelete();
                      }}
                      size="small"
                      sx={{ pointerEvents: "auto" }}
                    >
                      <DeleteOutlinedIcon fontSize="small" color="primary" />
                    </IconButton>
                  )}
                </Stack>

                {isLoading && (
                  <LinearProgress sx={{ width: "100%" }} value={progress} />
                )}
              </Stack>
            ) : (
              <>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  borderRadius={"200px"}
                  border={"1px solid #D0D5DD"}
                  width={"38px"}
                  height={"38px"}
                >
                  <img src={uploadIcon} alt="" />
                </Box>
                <Typography fontSize={"14px"} marginTop="13px">
                  <Typography color="secondary" component={"span"}>
                    {t("ClickToUpload")}
                  </Typography>{" "}
                  {t("orDragAndDrop")}
                </Typography>
              </>
            )}
          </Box>
          <FormHelperText error={error} id="my-helper-text" sx={{ px: 2 }}>
            {helperText}
          </FormHelperText>
        </Stack>
      )}
    />
  );
};

export default FileUploader;
