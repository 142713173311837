import { IconButton, Stack, Tooltip } from "@mui/material";
import EditIcon from "src/assets/svg-icons/aggregators/edit.svg";
import DeleteIcon from "src/assets/svg-icons/aggregators/delete.svg";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useTranslation } from "react-i18next";
import { ConfirmPopup } from "src/shared/components/confirm-popup";
import AddEditUnitPopup from "../add-edit-unit";
import { useDeleteUnitMutation } from "src/api/purchasing/items";
import { InventoryPermissions } from "src/constants/permissions";
import { oneBranchHasPermission } from "src/utils";

interface IAction {
  row: any;
  refetch: Function;
}

const TableActions = (props: IAction) => {
  const { t: T } = useTranslation("general");
  const { row, refetch } = props;
  const [open, setOpen] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  // APIS
  const { mutate, isPending, status, error } = useDeleteUnitMutation();

  // permissions
  const editPermission = oneBranchHasPermission(InventoryPermissions.editItem);
  const deletePermission = oneBranchHasPermission(
    InventoryPermissions.deleteItem
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenEditPopup = () => setOpenEditPopup(true);
  const handleCloseEditPopup = () => setOpenEditPopup(false);

  const handleDelete = () => {
    mutate(row.id);
  };

  useEffect(() => {
    if (status === "success") {
      refetch();
      handleClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: T("deletedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || T("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Stack direction={"row"} spacing={"12px"} justifyContent={"center"}>
      {editPermission && (
        <Tooltip title={T("edit")}>
          <IconButton onClick={handleOpenEditPopup}>
            <img src={EditIcon} alt="" />
          </IconButton>
        </Tooltip>
      )}

      {deletePermission && (
        <Tooltip title={T("delete")}>
          <IconButton onClick={handleOpen}>
            <img src={DeleteIcon} alt="" />
          </IconButton>
        </Tooltip>
      )}

      <AddEditUnitPopup
        open={openEditPopup}
        handleClose={handleCloseEditPopup}
        itemToUpdate={row}
        refetch={refetch}
      />

      <ConfirmPopup
        open={open}
        item={row.name}
        handleClose={handleClose}
        handleConfirm={handleDelete}
        isPending={isPending}
      />
    </Stack>
  );
};
export default TableActions;
