import {
  Box,
  Button,
  Drawer,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useGetBranches, useGetPaymentMethods } from "src/api/generic";
import FilterIcon from "src/assets/svg-icons/aggregators/filter.svg";
import { useTranslation } from "react-i18next";
import { useGetSuppliersList } from "src/api/purchasing/suppliers";
import { PurchasingPermissions } from "src/constants/permissions";
import { specificBranchHasPermission } from "src/utils";

interface IProps {
  open: boolean;
  handleClose: any;
  refNum: string;
  setRefNum: Function;
  invoiceNum: string;
  setInvoiceNum: Function;
  paymentId: string;
  setPaymentId: Function;
  supplierId: string;
  setSupplierId: Function;
  status: string;
  setStatus: Function;
  totalWithTax: string;
  setTotalWithTax: Function;
  branchId: string;
  setBranchId: Function;
}
const FilterDrawer = (props: IProps) => {
  const {
    open,
    handleClose,
    refNum,
    setRefNum,
    paymentId,
    setPaymentId,
    supplierId,
    setSupplierId,
    invoiceNum,
    setInvoiceNum,
    status,
    setStatus,
    totalWithTax,
    setTotalWithTax,
    branchId,
    setBranchId,
  } = props;
  const { t } = useTranslation("purchasing");
  const { t: generalT } = useTranslation("general");
  const { t: ocT } = useTranslation("operationCost");

  // APIS
  const { data: paymentsData } = useGetPaymentMethods();
  const { data: suppliersData } = useGetSuppliersList();
  const { data: branchesData } = useGetBranches();

  // permissions
  const branchesHavePermission =
    branchesData?.data.filter((branch) =>
      specificBranchHasPermission(
        PurchasingPermissions.viewPurchasingList,
        branch.id
      )
    ) || [];

  const handleClearFilters = () => {
    handleClose();
    setRefNum("");
    setInvoiceNum("");
    setPaymentId();
    setSupplierId();
    setStatus("all");
    setBranchId();
    setTotalWithTax("");
  };

  const Types = [
    {
      label: generalT("all"),
      value: "all",
    },
    {
      label: ocT("paid"),
      value: "paid",
    },
    {
      label: ocT("unpaid"),
      value: "unpaid",
    },
    {
      label: ocT("partiallyPaid"),
      value: "partial_paid",
    },
  ];

  return (
    <Drawer anchor={"right"} open={open} onClose={handleClose}>
      <Stack
        maxWidth={{ xs: "100%", sm: "376px" }}
        minWidth={{ xs: "100%", sm: "376px" }}
        borderRight={"1px solid #D0D5DD"}
        height={"100%"}
        maxHeight={"100vh"}
        top={"0"}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          p={"20px 27px"}
          bgcolor={"#fff"}
          boxShadow="0px 0px 40px 0px rgba(11, 24, 42, 0.07)"
        >
          <Button
            color="tertiary"
            variant="text"
            size="small"
            endIcon={<img src={FilterIcon} height={"16px"} alt="" />}
          >
            {t("filter")}
          </Button>
          <Button
            variant="outlined"
            color="tertiary"
            size="small"
            onClick={handleClose}
          >
            {generalT("hide")}
          </Button>
        </Box>
        <Stack spacing={"20px"} p={"40px 27px"}>
          <TextField
            label={t("reference")}
            variant="outlined"
            size="small"
            fullWidth
            value={refNum}
            onChange={(e) => setRefNum(e.target.value)}
          />
          <TextField
            type="number"
            label={generalT("totalWithTax")}
            variant="outlined"
            size="small"
            fullWidth
            value={totalWithTax}
            onChange={(e) => setTotalWithTax(e.target.value)}
          />
          <TextField
            label={t("invoiceNumber")}
            variant="outlined"
            size="small"
            fullWidth
            value={invoiceNum}
            onChange={(e) => setInvoiceNum(e.target.value)}
          />
          {/*  status */}
          <FormControl fullWidth size="small">
            <InputLabel id="demo-multiple-checkbox-label">
              {t("status")}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              input={<OutlinedInput label={t("status")} />}
            >
              {Types.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  <ListItemText primary={label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/*  payment Method  Select */}
          <FormControl fullWidth size="small">
            <InputLabel id="demo-multiple-checkbox-label">
              {t("paymentMethod")}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={paymentId}
              onChange={(e) => setPaymentId(e.target.value)}
              input={<OutlinedInput label={t("paymentMethod")} />}
            >
              {paymentsData?.data?.map((payment: any) => (
                <MenuItem key={payment.id} value={payment.id}>
                  <ListItemText primary={payment.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/*  Supplier Select */}
          <FormControl fullWidth size="small">
            <InputLabel id="demo-multiple-checkbox-label">
              {t("supplier")}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={supplierId}
              onChange={(e) => setSupplierId(e.target.value)}
              input={<OutlinedInput label={t("supplier")} />}
            >
              {suppliersData?.data?.map((sup: any) => (
                <MenuItem key={sup.id} value={sup.id}>
                  <ListItemText primary={sup.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/*  Branch Select */}
          <FormControl fullWidth size="small">
            <InputLabel id="demo-multiple-checkbox-label">
              {generalT("branch")}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={branchId}
              onChange={(e) => setBranchId(e.target.value)}
              input={<OutlinedInput label={generalT("branch")} />}
            >
              {branchesHavePermission?.map((branch) => (
                <MenuItem key={branch.id} value={branch.id}>
                  <ListItemText primary={branch.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* divider */}
          <Box width={"100%"} height={"1px"} bgcolor={"#CBD5E1"} />
        </Stack>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          p={"20px 27px"}
          bgcolor={"#fff"}
          boxShadow="0px 0px 40px 0px rgba(11, 24, 42, 0.07)"
          alignItems={"flex-end"}
          mt="auto"
        >
          <Button variant="contained" size="small" onClick={handleClose}>
            {generalT("apply")}
          </Button>
          <Button
            variant="outlined"
            color="tertiary"
            size="small"
            onClick={handleClearFilters}
          >
            {generalT("clear")}
          </Button>
        </Box>
      </Stack>
    </Drawer>
  );
};
export default FilterDrawer;
