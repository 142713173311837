export enum OCPermissions {
  // summary
  viewOCSummary = "view_operation_cost_summary",
  // list
  viewOCList = "view_operation_cost",
  addOC = "add_operation_cost",
  editOC = "edit_operation_cost",
  deleteOC = "delete_operation_cost",
  payOC = "pay_operation_cost",
  //accounts
  viewOCAccounts = "view_accounts",
  addOCAccount = "add_account",
  editOCAccount = "edit_account",
  deleteOCAccount = "delete_account",
  changeOCAccountStatus = "change_account_status",
}

export enum PurchasingPermissions {
  // summary
  viewSummary = "view_purchase_summary",
  // purchasing list
  viewPurchasingList = "view_purchases",
  addPurchasing = "add_purchase",
  editPurchasing = "edit_purchase",
  deletePurchasing = "delete_purchase",
  payPurchasing = "pay_purchase",
  //suppliers
  viewSupplier = "view_suppliers",
  addSupplier = "add_supplier",
  editSupplier = "edit_supplier",
  deleteSupplier = "delete_supplier",
  //events
  viewEvent = "view_purchase_events",
  addEvent = "add_purchase_event",
  deleteEvent = "delete_purchase_event",
}

export enum SettingsPermissions {
  // branch list
  viewBranches = "view_branches",
  addBranch = "add_branch",
  editBranch = "edit_branch",
  changeBranchStatus = "change_branch_status",
  //users
  viewUsers = "view_users",
  addUser = "add_user",
  editUser = "edit_user",
  deleteUser = "delete_user",
  //roles
  viewRole = "view_roles",
  addRole = "add_role",
  editRole = "edit_role",
  deleteRole = "delete_role",
  // payments list
  viewPaymentList = "view_payment_methods",
  addPayment = "add_payment_method",
  editPayment = "edit_payment_method",
  deletePayment = "delete_payment_method",
  changePaymentStatus = "change_payment_method_status",
  // payments transactions
  viewPaymentTransactions = "view_payment_transactions",
  //sales
  viewSales = "view_sales",
  addSale = "add_sales",
  editSale = "edit_sales",
  deleteSale = "delete_sales",
  //capital
  viewCapitals = "view_capitals",
  addCapital = "add_capital",
  editCapital = "edit_capital",
  deleteCapital = "delete_capital",
}

export enum InventoryPermissions {
  // summary
  viewSummary = "view_inventory_summary",
  // items list
  viewItems = "view_items",
  addItem = "add_item",
  editItem = "edit_item",
  deleteItem = "delete_item",
  // Unit cost Adjustment
  viewUnitCostAdjustments = "view_unit_cost_adjustments",
  addUnitCostAdjustment = "manage_unit_cost_adjustment",
  //stocktaking
  viewStocktaking = "view_stocktaking",
  addStocktaking = "add_stocktaking",
  deleteStocktaking = "delete_stocktaking",
  confirmStocktaking = "confirm_stocktaking",
  deleteConfirmedStocktaking = "delete_confirm_stocktaking",
  //waste formula
  viewWasteFormulas = "view_waste_formulas",
  addWasteFormula = "add_waste_formula",
  editWasteFormula = "edit_waste_formula",
  deleteWasteFormula = "delete_waste_formula",
  // level
  viewLevel = "view_level",
  // COGS
  viewCogs = "view_cogs",
  addCogs = "add_cogs",
  editCogs = "edit_cogs",
  deleteCogs = "delete_cogs",
  // transfers
  viewTransfers = "view_transfers",
  addTransfer = "add_transfer",
  confirmTransfer = "confirm_transfer",
  deleteTransfer = "delete_transfer",
}
