import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableSwitch from "./table-switch";
import TableActions from "./table-actions";
import {
  useGetAggregatorsList,
  useUpdateAggMutation,
} from "src/api/aggreagators";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import { useTranslation } from "react-i18next";
import { specificBranchHasPermission } from "src/utils";

const AggregatorsTable = () => {
  const { t } = useTranslation("aggregators");
  const tableHeadText = [
    t("aggName"),
    t("fee"),
    t("branch"),
    t("relatedPayments"),
    t("createdAt"),
    t("createdBy"),
    t("activeInactive"),
    t("actions"),
  ];
  const { data, isLoading, refetch } = useGetAggregatorsList();
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  const { mutate, error, status: statusUpdate } = useUpdateAggMutation();

  useEffect(() => {
    if (statusUpdate === "success") {
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: t("updatedSuccessfully"),
        },
      ]);
    } else if (statusUpdate === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || t("somethingWrong"),
        },
      ]);
    }
  }, [statusUpdate]);

  const emptyDataArr = data?.data.length === 0;

  return (
    <TableContainer component={Paper} variant="outlined">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableHeadText.map((item) => (
              <TableCell
                align="center"
                sx={{
                  fontSize: "12px",
                  fontWeight: 600,
                  lineHeight: "18px",
                  color: "#475467",
                  bgcolor: "#F9FAFB",
                }}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {isLoading ? (
          <TableLoadingSkeleton rowsLength={8} cellsLength={8} />
        ) : (
          <TableBody>
            {data?.data.map((row: any) => {
              //permissions
              const changeStatusPermission = specificBranchHasPermission(
                "change_aggregator_status",
                row.branch_id
              );
              return (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">%{row.aggregator_fee}</TableCell>
                  <TableCell align="center">{row.branch.name}</TableCell>
                  <TableCell align="center" sx={{ maxWidth: "250px" }}>
                    {row.related_payment_methods.map(
                      (payment: any, index: number) =>
                        `${payment.payment_method?.name || ""}${
                          index !== row.related_payment_methods.length - 1
                            ? " - "
                            : ""
                        }`
                    )}
                  </TableCell>
                  <TableCell align="center" dir="ltr">
                    {row.created_at}
                  </TableCell>
                  <TableCell align="center">{row.created_by?.name}</TableCell>
                  <TableCell align="center">
                    <TableSwitch
                      checked={row.status}
                      onChange={(e) => {
                        mutate({ id: row.id, status: e.target.checked });
                      }}
                      disabled={!changeStatusPermission}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TableActions
                      rowId={row.id}
                      branchId={row.branch_id}
                      rowName={row.name}
                      isDeletable={row.is_deletable}
                      refetch={refetch}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
      {emptyDataArr && <NoData />}
    </TableContainer>
  );
};
export default AggregatorsTable;
