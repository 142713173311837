import { FormControlLabel, Stack, Switch, SwitchProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ChangeEvent } from "react";

const TableSwitch = ({
  checked,
  onChange,
  disabled
}: {
  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  disabled?: boolean
}) => {
  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch disableRipple {...props} />
  ))(({ theme }) => ({
    width: 36,
    height: 20,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: " #FF7F29",
          opacity: 1,
          border: 2,
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 16,
      height: 16,
    },
    "& .MuiSwitch-track": {
      borderRadius: 12,
      backgroundColor: "#F2F4F7",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <Stack width={"100%"} alignItems={"center"}>
      <FormControlLabel
        control={
          <IOSSwitch
            sx={{ m: 1 }}
            defaultChecked={checked}
            onChange={onChange}
            disabled={disabled}
          />
        }
        label={""}
      />
    </Stack>
  );
};

export default TableSwitch;
