import { useMutation, useQuery } from "@tanstack/react-query";
import apiClient from "./apiClient";
import {
  AddCompanyAPIBody,
  AddCompanyAPIResponse,
  ICOGS,
} from "src/types/add-sales";
import { APIErrorResponse } from "src/types/generic";

export const useAddCompanyMutation = () =>
  useMutation<AddCompanyAPIResponse, APIErrorResponse, AddCompanyAPIBody>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/companies`, data);
    },
  });

export const useUpdateCompanyMutation = (id: string) =>
  useMutation<any, APIErrorResponse, AddCompanyAPIBody>({
    mutationFn: async (data) => {
      return apiClient.put(`/api/companies/${id}`, data);
    },
  });

export const useGetCompany = () =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-my-company-details"],
    queryFn: async () => {
      return apiClient.get(`/api/companies`);
    },
    gcTime: 0,
  });

// Journey foodics files
export const useGetJourneyFiles = (
  journeyId: string,
  enabled: boolean = true
) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-journey-foodics-files", journeyId],
    queryFn: async () => {
      return apiClient.get(`/api/journeyFiles?journey_id=${journeyId}`);
    },
    enabled: enabled,
    gcTime: 0,
  });

export const useUploadJourneyFileMutation = () =>
  useMutation<any, APIErrorResponse, any>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/journeyFiles`, data);
    },
  });

export const useDeleteJourneyFile = () =>
  useMutation<any, APIErrorResponse, string>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/journeyFiles/${id}`);
    },
  });

export const useDeleteJourneyPaymentMethods = () =>
  useMutation<any, APIErrorResponse, string>({
    mutationFn: async (id) => {
      return apiClient.delete(
        `/api/journeys/delete-journey-payment-methods/${id}`
      );
    },
  });

// Get Journey
export const useGetJourneyById = ({
  journeyId,
  enabled = true,
}: {
  journeyId: string;
  enabled?: boolean;
}) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-journey-by-id", journeyId],
    queryFn: async () => {
      return apiClient.get(`/api/journeys/${journeyId}`);
    },
    enabled: enabled,
    gcTime: 0,
  });

// create Orders Sales
export const useCreateOrdersSalesMutation = () =>
  useMutation<any, APIErrorResponse, { journey_id: number }>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/orders`, data);
    },
  });

export const useCreateJourneyMutation = () =>
  useMutation<any, APIErrorResponse, any>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/journeys`, data);
    },
  });

export const useUpdateJourneyMutation = () =>
  useMutation<any, APIErrorResponse, any>({
    mutationFn: async (data) => {
      const { journey_id, ...rest } = data;
      return apiClient.put(`/api/journeys/${journey_id}`, rest);
    },
  });

export const useGetDraftJourney = ({
  branch_id,
  enabled,
}: {
  branch_id: string;
  enabled: boolean;
}) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-draft-journey", branch_id],
    queryFn: async () => {
      return apiClient.get(
        `/api/journeys/get-draft-journey?branch_id=${branch_id}`
      );
    },
    enabled,
    gcTime: 0,
  });

// All journeys date range

export const useGetAllJourneysDateRange = ({
  branch_id,
  enabled,
}: {
  branch_id: string;
  enabled: boolean;
}) =>
  useQuery<void, APIErrorResponse, { start_date: string; end_date: string }>({
    queryKey: ["get-all-journeys-date-range", branch_id],
    queryFn: async () => {
      return apiClient.get(
        `/api/journeys/get-current-date-journey-range?branch_id=${branch_id}`
      );
    },
    enabled,
    gcTime: 0,
  });

export const useGetLastCogs = ({
  branch_id,
  enabled,
}: {
  branch_id: string;
  enabled: boolean;
}) =>
  useQuery<
    void,
    APIErrorResponse,
    {
      data: ICOGS;
    }
  >({
    queryKey: ["get-last-cogs", branch_id],
    queryFn: async () => {
      return apiClient.get(`/api/cogses/get-last-cogs?branch_id=${branch_id}`);
    },
    enabled,
    gcTime: 0,
  });

interface IDaysOffAPIBody {
  branch_id: number;
  start_date: string;
  end_date: string;
}

export const useDaysOffMutation = () =>
  useMutation<any, APIErrorResponse, IDaysOffAPIBody>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/add-dayoff`, data);
    },
  });

// packages
export const useGetAllPackages = () =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-all-packages"],
    queryFn: async () => {
      return apiClient.get(`/api/admin/packages`);
    },
    gcTime: 0,
  });

interface IBranchPackage {
  branch_id: number;
  package_id: number;
  duration: number;
}

export const useAddBranchPackageMutation = () =>
  useMutation<any, APIErrorResponse, IBranchPackage>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/subscriptions`, data);
    },
  });

export const useGetLastJourneyData = ({
  branch_id,
  enabled,
}: {
  branch_id: string;
  enabled: boolean;
}) =>
  useQuery<void, APIErrorResponse, any>({
    queryKey: ["get-last-journey-data", branch_id],
    queryFn: async () => {
      return apiClient.get(
        `/api/journeys/get-last-journey?branch_id=${branch_id}`
      );
    },
    enabled: enabled,
    gcTime: 0,
  });
