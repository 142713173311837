import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "../apiClient";
import { IPermissionsDataResponse } from "src/types/settings";

interface IParams {
  page?: number;
}

export const useGetPermissions = () =>
  useQuery<void, APIErrorResponse, IPermissionsDataResponse>({
    queryKey: ["get-permissions"],
    queryFn: async () => {
      return apiClient.get(`/api/permissions`);
    },
  });

export const useAddRoleMutation = () =>
  useMutation<
    void,
    APIErrorResponse,
    { role_name: string; permissions: number[] }
  >({
    mutationFn: async (data) => {
      return apiClient.post(`/api/roles`, data);
    },
  });

export const useUpdateRoleMutation = () =>
  useMutation<
    void,
    APIErrorResponse,
    { id: string; role_name: string; permissions: number[] }
  >({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      return apiClient.put(`/api/roles/${id}`, rest);
    },
  });

export const useGetRoles = (params?: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-roles"],
    queryFn: async () => {
      return apiClient.get(`/api/roles`, { params: params ? params : {} });
    },
  });

export const useGetRoleById = ({
  id,
  enabled,
}: {
  id: string;
  enabled: boolean;
}) =>
  useQuery<void, APIErrorResponse, any>({
    queryKey: ["get-role-by-id"],
    queryFn: async () => {
      return apiClient.get(`/api/roles/${id}`);
    },
    gcTime: 0,
    enabled,
  });

export const useDeleteRoleMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/roles/${id}`);
    },
  });
