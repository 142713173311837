import { IconButton, Stack } from "@mui/material";
import EditIcon from "src/assets/svg-icons/aggregators/edit.svg";
import DeleteIcon from "src/assets/svg-icons/aggregators/delete.svg";
import { ConfirmPopup } from "src/shared/components/confirm-popup";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useDeleteUserMutation } from "src/api/settings/users";
import AddEditUser from "../../add-edit-user";
import { useTranslation } from "react-i18next";
import { oneBranchHasPermission } from "src/utils";
import { SettingsPermissions } from "src/constants/permissions";

const TableActions = ({
  refetch,
  userData,
  type,
}: {
  refetch: Function;
  userData: any;
  type: string;
}) => {
  const [open, setOpen] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const { t } = useTranslation("general");

  const { mutate, isPending, status, error } = useDeleteUserMutation();

  // permissions
  const editPermission = oneBranchHasPermission(SettingsPermissions.editUser);
  const deletePermission = oneBranchHasPermission(
    SettingsPermissions.deleteUser
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleDelete = () => {
    mutate(userData.id);
  };

  useEffect(() => {
    if (status === "success") {
      refetch();
      handleClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: t("deletedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || t("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Stack
      direction={"row"}
      spacing={"12px"}
      width={"100%"}
      justifyContent={"center"}
    >
      {type !== "owner" && (
        <>
          {editPermission && (
            <IconButton onClick={handleOpenEditModal}>
              <img src={EditIcon} alt="" />
            </IconButton>
          )}
          {deletePermission && (
            <IconButton onClick={handleOpen}>
              <img src={DeleteIcon} alt="" />
            </IconButton>
          )}
        </>
      )}

      <ConfirmPopup
        open={open}
        item={userData.name}
        handleClose={handleClose}
        handleConfirm={handleDelete}
        isPending={isPending}
      />
      {openEditModal && (
        <AddEditUser
          open={openEditModal}
          handleClose={handleCloseEditModal}
          refetch={refetch}
          userData={userData}
        />
      )}
    </Stack>
  );
};
export default TableActions;
