import { IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import EditIcon from "src/assets/svg-icons/aggregators/edit.svg";
import DeleteIcon from "src/assets/svg-icons/aggregators/delete.svg";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AddEditTransactionPopup from "../../add-edit-purchasing";
import PurchaseAttachmentsPopup from "../../attachments-popup";
import AddPurchasePaymentPopup from "../../pay-popup";
import { ConfirmPopup } from "src/shared/components/confirm-popup";
import { useDeletePurchaseMutation } from "src/api/purchasing/purchasing";
import { specificBranchHasPermission } from "src/utils";
import { PurchasingPermissions } from "src/constants/permissions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";

interface IAction {
  row: any;
  refetch: Function;
}

const TableActions = (props: IAction) => {
  const { row, refetch } = props;
  const { id, reference } = row;
  const { t } = useTranslation("general");
  const navigate = useNavigate();
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [openDelete, setOpenDelete] = useState(false);
  const [openPay, setOpenPay] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAttachmentsModal, setOpenAttachmentsModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // APIS
  const { mutate, isPending, status, error } = useDeletePurchaseMutation();

  //permissions
  const editPermission = specificBranchHasPermission(
    PurchasingPermissions.editPurchasing,
    row.branch?.id
  );
  const deletePermission = specificBranchHasPermission(
    PurchasingPermissions.deletePurchasing,
    row.branch?.id
  );
  const payPermission = specificBranchHasPermission(
    PurchasingPermissions.payPurchasing,
    row.branch?.id
  );

  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const handleOpenPay = () => setOpenPay(true);
  const handleClosePay = () => setOpenPay(false);

  const handleOpenAttachmentsModal = () => setOpenAttachmentsModal(true);

  const handleCloseAttachmentsModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setOpenAttachmentsModal(false);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToTransDetails = () => {
    navigate(`/purchasing/${id}`);
  };

  const handleDelete = () => {
    mutate(id);
  };

  useEffect(() => {
    if (status === "success") {
      refetch();
      handleCloseDelete();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: t("deletedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || t("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Stack direction={"row"} spacing={"12px"} justifyContent={"flex-start"}>
      <IconButton size="small" onClick={(e) => handleClick(e)}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleOpenAttachmentsModal} sx={{ gap: 1 }}>
          <img src={DownloadIcon} alt="" />
          <Typography
            color={"#1D2939"}
            fontSize={"16px"}
            textTransform={"capitalize"}
          >
            {t("attachments")}
          </Typography>
        </MenuItem>
        {editPermission && (
          <MenuItem onClick={handleOpenEdit} sx={{ gap: 1 }}>
            <img src={EditIcon} alt="" />
            <Typography
              color={"#1D2939"}
              fontSize={"16px"}
              textTransform={"capitalize"}
            >
              {t("edit")}
            </Typography>
          </MenuItem>
        )}

        {deletePermission && (
          <MenuItem onClick={handleOpenDelete} sx={{ gap: 1 }}>
            <img src={DeleteIcon} alt="" />
            <Typography
              color={"#1D2939"}
              fontSize={"16px"}
              textTransform={"capitalize"}
            >
              {t("delete")}
            </Typography>
          </MenuItem>
        )}

        <MenuItem onClick={navigateToTransDetails} sx={{ gap: 1 }}>
          <VisibilityOutlinedIcon sx={{ fontSize: "16px" }} />
          <Typography
            color={"#1D2939"}
            fontSize={"16px"}
            textTransform={"capitalize"}
          >
            {t("details")}
          </Typography>
        </MenuItem>

        {payPermission && row?.status !== "Paid" && (
          <MenuItem onClick={handleOpenPay} sx={{ gap: 1 }}>
            <PaidOutlinedIcon sx={{ fontSize: "16px" }} />
            <Typography
              color={"#1D2939"}
              fontSize={"16px"}
              textTransform={"capitalize"}
            >
              {`${t("pay")}!`}
            </Typography>
          </MenuItem>
        )}
      </Menu>

      {/* confirm delete popup */}
      <ConfirmPopup
        open={openDelete}
        item={reference}
        handleClose={handleCloseDelete}
        handleConfirm={handleDelete}
        isPending={isPending}
      />
      {/* payment popup */}
      {openPay && (
        <AddPurchasePaymentPopup
          open={openPay}
          handleClose={handleClosePay}
          refetch={refetch}
          row={row}
        />
      )}

      {/* edit popup */}
      {openEdit && (
        <AddEditTransactionPopup
          open={openEdit}
          handleClose={handleCloseEdit}
          purchaseToEdit={row}
          refetch={refetch}
        />
      )}
      {/* attachments popup */}
      {openAttachmentsModal && (
        <PurchaseAttachmentsPopup
          open={openAttachmentsModal}
          handleClose={handleCloseAttachmentsModal}
          rowId={row.id}
        />
      )}
    </Stack>
  );
};
export default TableActions;
