import { Box, Button, Stack, TablePagination, Typography } from "@mui/material";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import FilterIcon from "src/assets/svg-icons/aggregators/filter.svg";
import { useEffect, useState } from "react";
import FilterDrawer from "./filters";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import AddEditPurchasingPopup from "./add-edit-purchasing";
import PurchasingTable from "./table";
import {
  useDownloadPurchasingList,
  useGetPurchasingList,
} from "src/api/purchasing/purchasing";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { downloadFile, oneBranchHasPermission } from "src/utils";
import DateRangeInput from "src/shared/components/date-range";
import moment from "moment";
import { PurchasingPermissions } from "src/constants/permissions";

const Purchasing = () => {
  const { t } = useTranslation("purchasing");
  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);
  const [openAddPurchasingPopup, setOpenAddPurchasingPopup] = useState(false);
  const [dateRange, setDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: "",
    endDate: "",
  });
  const [refNum, setRefNum] = useState("");
  const [invoiceNum, setInvoiceNum] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [totalWithTax, setTotalWithTax] = useState("");
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [filterStatus, setFilterStatus] = useState("all");
  const [branchId, setBranchId] = useState("");

  //  permissions
  const addPermission = oneBranchHasPermission(
    PurchasingPermissions.addPurchasing
  );

  const filters = {
    ...(!!refNum && { reference_number: refNum }),
    ...(!!invoiceNum && { invoice_number: invoiceNum }),
    ...(!!totalWithTax && { total: totalWithTax }),
    ...(!!filterStatus && filterStatus !== "all" && { status: filterStatus }),
    ...(!!paymentId && { payment_method_id: paymentId }),
    ...(!!supplierId && { supplier_id: supplierId }),
    ...(!!branchId && { branch_id: branchId }),
    start_date: dateRange.startDate || undefined,
    end_date: dateRange.endDate || undefined,
  };

  // APIS
  const { data, refetch, isFetching } = useGetPurchasingList({
    page: pageNumber + 1,
    ...filters,
  });
  const {
    data: downloadedData,
    refetch: refetchDownload,
    isFetching: isFetchingDownload,
    status,
  } = useDownloadPurchasingList(filters);

  useEffect(() => {
    if (!openFilter) {
      setPageNumber(0);
      refetch();
    }
  }, [openFilter]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  useEffect(() => {
    if (!!data) {
      setTotal(data.meta.total);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [dateRange.endDate, dateRange.startDate]);

  const handleAddPurchasingPopup = () => {
    setOpenAddPurchasingPopup(true);
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleClose = () => {
    setOpenFilter(false);
    setOpenAddPurchasingPopup(false);
  };

  const handleNavigate = () => {
    navigate("/purchasing/import");
  };

  const handleDownload = () => {
    refetchDownload();
  };

  useEffect(() => {
    if (status === "success" && !!downloadedData) {
      const utf8CSVData = `\uFEFF${downloadedData}`;
      downloadFile(utf8CSVData, "text/csv", "purchasing");
    }
  }, [isFetchingDownload]);

  return (
    <Stack spacing={3} maxWidth={{ xs: "100%", md: `calc(100vw - 316px)` }}>
      <Stack
        sx={{ flexDirection: { xs: "column", md: "row" } }}
        justifyContent={"space-between"}
        gap={2}
        alignItems={"center"}
      >
        <Typography
          fontWeight={600}
          lineHeight={"19.2px"}
          color={"#475467"}
          textTransform={"uppercase"}
        >
          {t("purchasing")}
        </Typography>
        <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
          <Box sx={{ minWidth: 207 }}>
            <DateRangeInput
              startDate={
                dateRange.startDate ? moment(dateRange.startDate) : null
              } // moment.Moment | null
              startDateId={`your_unique_start_date_id`}
              endDate={dateRange.endDate ? moment(dateRange.endDate) : null} // moment.Moment | null
              endDateId={`your_unique_end_date_id`}
              onDatesChange={(arg: {
                startDate: moment.Moment | null;
                endDate: moment.Moment | null;
              }) => {
                setDateRange({
                  startDate: arg.startDate?.format("yyyy-MM-DD") as string,
                  endDate: arg.endDate?.format("yyyy-MM-DD") as string,
                });
              }}
              showClearDates
            />
          </Box>
          <Button
            color="tertiary"
            variant="outlined"
            startIcon={<img src={FilterIcon} alt="" />}
            onClick={handleOpenFilter}
          >
            {t("filter")}
          </Button>
          <LoadingButton
            sx={{
              border: "1px solid #98A2B3",
              height: "40px",
              fontWeight: 500,
              borderRadius: "4px",
              padding: "7px 10px",
              background: "#FCFCFD",
              fontSize: "16px",
            }}
            color="tertiary"
            variant="outlined"
            endIcon={<img src={DownloadIcon} alt="" />}
            loading={isFetchingDownload}
            onClick={handleDownload}
          >
            {t("download")}
          </LoadingButton>
          {addPermission && (
            <>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleAddPurchasingPopup}
              >
                {t("addPurchasing")}
              </Button>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleNavigate}
              >
                {t("importPurchasing")}
              </Button>
            </>
          )}
        </Stack>
        <FilterDrawer
          open={openFilter}
          refNum={refNum}
          setRefNum={setRefNum}
          invoiceNum={invoiceNum}
          setInvoiceNum={setInvoiceNum}
          paymentId={paymentId}
          setPaymentId={setPaymentId}
          supplierId={supplierId}
          setSupplierId={setSupplierId}
          status={filterStatus}
          setStatus={setFilterStatus}
          totalWithTax={totalWithTax}
          setTotalWithTax={setTotalWithTax}
          handleClose={handleClose}
          branchId={branchId}
          setBranchId={setBranchId}
        />
      </Stack>
      <PurchasingTable
        data={data?.data}
        refetch={refetch}
        isFetching={isFetching}
      />
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={total}
        rowsPerPage={20}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />
      {openAddPurchasingPopup && (
        <AddEditPurchasingPopup
          open={openAddPurchasingPopup}
          handleClose={handleClose}
          refetch={refetch}
        />
      )}
    </Stack>
  );
};

export default Purchasing;
