import { Button, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CogsTable from "./table";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { InventoryPermissions } from "src/constants/permissions";
import { oneBranchHasPermission } from "src/utils";

const Cogs = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("settings");

  // permissions
  const addPermission = oneBranchHasPermission(InventoryPermissions.addCogs);

  const handleNavigate = () => {
    navigate("/inventory/cogs/add-new-cogs");
  };

  return (
    <Stack spacing={3}>
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography fontWeight={600} lineHeight={"19.2px"} color={"#475467"}>
          {t("cogsList")}
        </Typography>
        {addPermission && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleNavigate}
          >
            {t("addNewCogs")}
          </Button>
        )}
      </Stack>
      <CogsTable />
    </Stack>
  );
};

export default Cogs;
