import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useGetAggregatorsList } from "src/api/aggreagators";
import { useGetPaymentMethods, useGetBranches } from "src/api/generic";
import FilterIcon from "src/assets/svg-icons/aggregators/filter.svg";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";
import { specificBranchHasPermission } from "src/utils";

interface IProps {
  open: boolean;
  handleClose: any;
  payments: number[];
  setPayments: Function;
  branches: number[];
  setBranches: Function;
  aggregators: number[];
  setAggregators: Function;
  refNum: string;
  setRefNum: Function;
  hasAggregatorDeliveryCost: string;
  setHasAggregatorDeliveryCost: Function;
}

const FilterDrawer = (props: IProps) => {
  const {
    open,
    handleClose,
    payments,
    setPayments,
    branches,
    setBranches,
    aggregators,
    setAggregators,
    refNum,
    setRefNum,
    hasAggregatorDeliveryCost,
    setHasAggregatorDeliveryCost,
  } = props;

  // APIS
  const { data: paymentsData } = useGetPaymentMethods();
  const { data: branchesData } = useGetBranches();
  const { data: aggData } = useGetAggregatorsList();

  // Permissions
  const aggsHavePermission =
    aggData?.data.filter((agg: any) =>
      specificBranchHasPermission("view_aggregator_transactions", agg.branch_id)
    ) || [];
  const branchesHavePermission =
    branchesData?.data.filter((branch) =>
      specificBranchHasPermission("view_aggregator_transactions", branch.id)
    ) || [];

  const { t } = useTranslation("aggregators");

  const handleChangePayments = (event: any) => {
    const {
      target: { value },
    } = event;
    setPayments(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeBranch = (event: any) => {
    const {
      target: { value },
    } = event;
    setBranches(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeAggregator = (event: any) => {
    const {
      target: { value },
    } = event;
    setAggregators(typeof value === "string" ? value.split(",") : value);
  };

  const handleClearFilters = () => {
    setPayments([]);
    setAggregators([]);
    setBranches([]);
    handleClose();
    setRefNum("");
    setHasAggregatorDeliveryCost("");
  };

  return (
    <Drawer anchor={"right"} open={open} onClose={handleClose}>
      <Stack
        maxWidth={{ xs: "100%", sm: "376px" }}
        minWidth={{ xs: "100%", sm: "376px" }}
        borderRight={"1px solid #D0D5DD"}
        height={"100%"}
        maxHeight={"100vh"}
        top={"0"}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          p={"20px 27px"}
          bgcolor={"#fff"}
          boxShadow="0px 0px 40px 0px rgba(11, 24, 42, 0.07)"
        >
          <Button
            color="tertiary"
            variant="text"
            size="small"
            endIcon={<img src={FilterIcon} height={"16px"} alt="" />}
          >
            {t("filter")}
          </Button>

          <Button
            variant="outlined"
            color="tertiary"
            size="small"
            onClick={handleClose}
          >
            {t("hide")}
          </Button>
        </Box>
        <Stack spacing={"20px"} p={"40px 27px"}>
          <TextField
            label={t("referenceNumber")}
            variant="outlined"
            size="small"
            fullWidth
            value={refNum}
            onChange={(e) => setRefNum(e.target.value)}
          />
          {/*  Branch Select */}
          <FormControl fullWidth size="small">
            <InputLabel id="demo-multiple-checkbox-label">
              {t("branch")}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={branches}
              onChange={handleChangeBranch}
              input={<OutlinedInput label={t("branch")} />}
              renderValue={(selected) =>
                selected
                  .map(
                    (selectedId: number) =>
                      branchesHavePermission.find(
                        (branch: any) => branch.id === +selectedId
                      )?.name
                  )
                  .join(", ")
              }
            >
              {branchesHavePermission.map((branch) => (
                <MenuItem
                  key={branch.id}
                  value={branch.id}
                  sx={{ padding: "0" }}
                >
                  <Checkbox
                    size="small"
                    checked={branches.indexOf(branch.id) > -1}
                  />
                  <ListItemText primary={branch.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/*  Aggregator  Select */}
          <FormControl fullWidth size="small">
            <InputLabel id="demo-multiple-checkbox-label">
              {t("agg")}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={aggregators}
              onChange={handleChangeAggregator}
              input={<OutlinedInput label={t("agg")} />}
              renderValue={(selected) =>
                selected
                  .map(
                    (selectedId: number) =>
                      aggsHavePermission.find(
                        (branch: any) => branch.id === +selectedId
                      )?.name
                  )
                  .join(", ")
              }
            >
              {aggsHavePermission.map((agg: any) => (
                <MenuItem key={agg.id} value={agg.id} sx={{ padding: "0" }}>
                  <Checkbox
                    size="small"
                    checked={aggregators?.indexOf(agg.id) > -1}
                  />
                  <ListItemText primary={agg.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* payments select */}
          <FormControl fullWidth size="small">
            <InputLabel id="demo-multiple-checkbox-label">
              {t("payments")}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={payments}
              onChange={handleChangePayments}
              input={<OutlinedInput label={t("payments")} />}
              renderValue={(selected) =>
                selected
                  .map(
                    (selectedId: number) =>
                      paymentsData?.data.find(
                        (branch: any) => branch.id === +selectedId
                      )?.name
                  )
                  .join(", ")
              }
            >
              {paymentsData?.data.map((branch) => (
                <MenuItem
                  key={branch.id}
                  value={branch.id}
                  sx={{ padding: "0" }}
                >
                  <Checkbox
                    size="small"
                    checked={payments?.indexOf(branch.id) > -1}
                  />
                  <ListItemText primary={branch.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* Has Aggregator delivery cost */}
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={1}
            flexWrap={"wrap"}
            width={"100%"}
          >
            <Typography color={"textSecondary"}>
              {t("aggDeliveryCost")}
            </Typography>
            <FormControl
              fullWidth
              size="small"
              sx={{ width: "fit-content", ml: { xs: "auto", sm: "unset" } }}
            >
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={hasAggregatorDeliveryCost}
                onChange={(e) => setHasAggregatorDeliveryCost(e.target.value)}
              >
                <FormControlLabel
                  value="1"
                  control={
                    <Radio
                      icon={<CircleOutlinedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                    />
                  }
                  label={t("yes")}
                  sx={{ color: "#98A2B3" }}
                />
                <FormControlLabel
                  value="0"
                  control={
                    <Radio
                      icon={<CircleOutlinedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                    />
                  }
                  label={t("no")}
                  sx={{ color: "#98A2B3" }}
                />
              </RadioGroup>
            </FormControl>
          </Stack>
          {/* divider */}
          <Box width={"100%"} height={"1px"} bgcolor={"#CBD5E1"} />
        </Stack>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          p={"20px 27px"}
          bgcolor={"#fff"}
          boxShadow="0px 0px 40px 0px rgba(11, 24, 42, 0.07)"
          alignItems={"flex-end"}
          mt="auto"
        >
          <Button variant="contained" size="small" onClick={handleClose}>
            {t("apply")}
          </Button>
          <Button
            variant="outlined"
            color="tertiary"
            size="small"
            onClick={handleClearFilters}
          >
            {t("clear")}
          </Button>
        </Box>
      </Stack>
    </Drawer>
  );
};
export default FilterDrawer;
